
<template>
    <div>
        <div class="market-profile-inner">
            <ul>
                <li class="li-head" style="padding-left: 0px;border-top: 0;">
                    <div class="profile-inner-table-row v-select-row-inner">
                        <div class="profile-inner-table-name">
                            <v-select :items="stock_balancesheet[type].headers"  v-model="selectBalancesheet" class="profile-mobile-view-select" v-on:change="changeS">
                                <template v-slot:selection="{ item }">
                                    <span class="vselect-span">{{ item.date }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <span class="vselect-span">{{ item.date }}</span>
                                </template>
                            </v-select>
                        </div>
                        <div class="profile-inner-table-value">
                            <div class="header-actions-sw" style="justify-content: left;">
                                <div :class="'header-actions-sw-item ' + (type == 1 ? 'sw-item-active' : '')" @click="updateType(1)">مجمع</div>
                                <div :class="'header-actions-sw-item ' + (type == 3 ? 'sw-item-active' : '')" @click="updateType(3)">میاندوره‌ای</div>
                            </div>
                        </div>
                    </div>
                </li>
                
                <slot v-if="widgetLoad">
                    <div class="text-center p-2 w100-w">
                        <div class="widget-loading"></div>
                    </div>
                </slot>

                <li v-else class="active-current">
                    <div class="profile-inner-table-row" v-for="(item, index) in stock_balancesheet[type].headers" :key="index" v-if="stock_balancesheet[type].headers && item == selectBalancesheet">
                        <div class="profile-inner-table-name">سال مالی</div>
                        <div class="profile-inner-table-value">{{ item.date }} <br> سال مالی {{ item.sal_mali }} <br> {{ item.cycle}}</div>
                    </div>
                    <div class="profile-inner-table-box profile-inner-table-box-full" v-for="(item, index) in stock_balancesheet[type].rows" :key="index" v-if="stock_balancesheet[type].rows">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row inner-balancesheet">
                                <div class="profile-inner-table-name">{{ index }}</div>
                                <div class="profile-inner-table-value">
                                    <slot v-if="tabIndex == 0">
                                        <div :class="'number-data-item ' + (roundInt(item[0].value) < 0 ? 'red' : '')">
                                            {{ roundInt(item[0].value) }}
                                        </div>
                                        <span style="font-size: 12px;color: #666;"> {{ intToString(item[0].value) }}</span>
                                    </slot>
                                    <slot v-if="tabIndex == 1">
                                        <div :class="'number-data-item ' + (roundInt(item[1].value) < 0 ? 'red' : '')">
                                            {{ roundInt(item[1].value) }}
                                        </div>
                                        <span style="font-size: 12px;color: #666;"> {{ intToString(item[1].value) }}</span>
                                    </slot>
                                    <slot v-if="tabIndex == 2">
                                        <div :class="'number-data-item ' + (roundInt(item[2].value) < 0 ? 'red' : '')">
                                            {{ roundInt(item[2].value) }}
                                        </div>
                                        <span style="font-size: 12px;color: #666;"> {{ intToString(item[2].value) }}</span>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {
    name: 'StockBalancesheet',
    data() {
        return {
            stock_balancesheet: null,
            selectBalancesheet: null,
            tabIndex: 0,
            type: 1,
            widgetLoad: true,
        }
    },
    mounted() {
        this.getProfileBalancesheet();
    },
    methods: {
        changeS(){
            var self = this;
            this.stock_balancesheet[this.type].headers.forEach(function (value, i) {
                if(self.selectBalancesheet == value){
                    self.tabIndex= i;
                }
            });            
        },
        updateType(type) {
            this.type = type;
            this.selectBalancesheet = this.stock_balancesheet[this.type].headers[0];
        },
        getProfileBalancesheet() {
            var self = this;
            this.$helpers
                .makeRequest("GET", `/stock/profile-balancesheet/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_balancesheet = api_response.data.response.balancesheet.body.response.data;
                        self.widgetLoad = false;
                        this.selectBalancesheet = this.stock_balancesheet[self.type].headers[0];
                    }
                });

        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>